import React from 'react';
import { Card, Row, Image, Col } from 'react-bootstrap';

import Carousel from '../components/Carousel';
import appstore from '../assets/appstore.png';
import playstore from '../assets/playstore.png';

const CardComponent = props => {
  return(
    <Card style={{width: '95%', padding: 50}}>
      <Card.Body>
        <Card.Title style={{fontSize: 30}}>{props.namaApp}</Card.Title>
        <Row>
          <Col xs={12} md={6} style={{marginTop: 20}}>
          <Carousel
            gambar={props.gambar}
          />
          </Col>
          <Col style={{marginLeft: 20, marginTop: 20}} xs={12} md={4}>
            <Card.Text>
              <Row style={{fontSize: 16}}>Kategori :</Row>
              <Row style={{fontSize: 20}}>{props.kategori}</Row>
            </Card.Text>
            <Card.Text>
              <Row style={{fontSize: 16}}>Created :</Row>
              <Row style={{fontSize: 20}}>{props.tahun}</Row>
            </Card.Text>
            <Card.Text>
              <Row style={{fontSize: 16}}>Main Function :</Row>
              <Row style={{fontSize: 20}}>{props.fungsi}</Row>
            </Card.Text>
            {
              props.website ?
                <Card.Text>
                  <Row style={{fontSize: 16}}>Website :</Row>
                  <Row style={{fontSize: 20}}><a href={props.website} target="_blank" rel="noopener noreferrer" >{props.website}</a></Row>
                </Card.Text>
                :null
            }
            <Card.Text>
              <Row style={{fontSize: 16}}>Download Link :</Row>
              <a href={props.playstore} target="_blank" rel="noopener noreferrer" >
                <Image
                  src={playstore}
                  fluid style={{height: 60, marginRight: 20,marginTop: 5}}
                />
              </a>
              <a href={props.appstore} target="_blank" rel="noopener noreferrer" >
                <Image
                  src={appstore}
                  fluid style={{height: 60,marginTop: 10}}
                />
              </a>
            </Card.Text>

          </Col>
        </Row>

      </Card.Body>
    </Card>
  )
};

export default CardComponent;
