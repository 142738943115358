import axios from 'axios';

export const httprequest = (url, loading) => {
  loading(true)
  return axios.get("https://quinnstechnology.com/web/" + url, {manual: true,  useCache: false, proxy: false})
  .then(({data}) => {
    loading(false)
    return data
  }, (error) => {
    loading(false)
    return alert("Silahkan cek koneksi internet anda")
  })
}
