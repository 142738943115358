import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';

const NavBarComponent = props => {
  return(
    <>
      <Navbar variant="dark" style={{justifyContent: 'center', backgroundColor: '#01030A', height: 65}} fixed="top">
        <Container>
          <Navbar.Brand>
            {props.title}
          </Navbar.Brand>
          <Nav defaultActiveKey="#Home">
            <Nav.Link onClick={() => props.changeToWelcome()} href="#Home">Home</Nav.Link>
            <Nav.Link onClick={() => props.changeToPesan()} href="#ContactUs">Contact Us</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  )
};

export default NavBarComponent;
