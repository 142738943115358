import React from 'react';
import { Carousel, Image } from 'react-bootstrap';

const CarouselComponent = props => {
  let gambar = props.gambar.split(",");
  return(
    <Carousel>
      {
        gambar.map((item, idx) => (
          <Carousel.Item key={idx} style={{flex: 1, justifyContent: 'center'}}>
            <Image
              src={item}
              fluid style={{height: 300}}
              style={{alignSelf: 'center'}}
            />
          </Carousel.Item>
        ))
      }
    </Carousel>
  )
};


export default CarouselComponent;
