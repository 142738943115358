import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import NavBar from './components/NavBar';

import WelcomeScreen from './screens/WelcomeScreen';
import PesanScreen from './screens/PesanScreen';

class App extends Component {
  state={
    menu: 'welcome'
  }
  componentDidMount(){
    document.title = "Quinn's Tech"
  }
  render(){
    return (
      <>
        <NavBar
          title="Quinn's Technology"
          changeToWelcome={() => this.setState({menu: 'welcome'})}
          changeToPesan={() => this.setState({menu: 'pesan'})}
        />
        <div style={{height: 50}} />
        {
          this.state.menu == 'welcome' ?
          <WelcomeScreen
            changeToPesan={() => this.setState({menu: 'pesan'})}
          />
          :
          <PesanScreen />
        }
      </>
    );
  }
}

export default App;
