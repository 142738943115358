import React, { Component } from 'react';
import { Form, Container, Col, Button, Row }  from 'react-bootstrap';
import { httprequest } from '../helper/axios';

class WelcomeScreen extends Component {
  state={
    email: '',
    nohp: '',
    judul: '',
    pesan: '',
    refreshing: false
  }

  handleSubmit = async() => {
    if(!this.state.email.trim()) return alert("Email tidak boleh kosong")
    if(!this.state.nohp.trim()) return alert("No HP tidak boleh kosong")
    if(!this.state.judul.trim()) return alert("Judul tidak boleh kosong")
    if(!this.state.pesan.trim()) return alert("Pesan tidak boleh kosong")
    let res = await httprequest("webservice.php?fungsi=pesan&email="+this.state.email+"&nohp="+this.state.nohp+"&judul="+this.state.judul+"&pesan="+this.state.pesan, e => this.setState({refreshing: e}))
    if(res == "Success"){
      this.setState({email:'', nohp: '', judul: '', pesan: ''})
      alert("Pesan Terkirim")
    }
  }

  render(){
    return(
      <Container style={{marginTop: 50}}>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="name@example.com" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2">
          <Form.Label>No HP</Form.Label>
          <Form.Control type="judul" value={this.state.nohp} onChange={(e) => this.setState({nohp: e.target.value})}/>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput3">
            <Form.Label>Judul</Form.Label>
            <Form.Control type="judul" value={this.state.judul} onChange={(e) => this.setState({judul: e.target.value})}/>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Pesan</Form.Label>
            <Form.Control as="textarea" rows={8} value={this.state.pesan} onChange={(e) => this.setState({pesan: e.target.value})}/>
          </Form.Group>

          <Button
            variant="primary" style={{width: 160}}
            disabled={this.state.refreshing}
            onClick={() => !this.state.refreshing ? this.handleSubmit() : null}
            >{!this.state.refreshing? 'Kirim' : 'Loading...'}</Button>
        </Form>
      </Container>

    )
  }
};

export default WelcomeScreen;
