import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';

const MainBackground = props => {
  return(
    <div style={{width:'100%', backgroundColor: '#01030A', paddingBottom: 50}}>
      <Container>
        <Row>
          <Col className="d-flex align-items-center" xs={20}  md={6}>

            <div>
              <Row style={{color: 'white', fontSize: 40}}>{props.title}</Row>
              <Row style={{color: 'white', fontSize: 20}}>{props.tagline}</Row>
              <Row style={{color: 'white', fontSize: 20, marginTop: 30}}><Button variant="primary" style={{width: 160}} onClick={()=>props.changeToPesan()}>Pesan Aplikasi</Button></Row>

            </div>
          </Col>
          <Col>
            <Image src={props.gambar} fluid style={{width: 600}} xs={20} md={4}/>
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default MainBackground;
