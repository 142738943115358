import React, { Component } from 'react';
import { Row }  from 'react-bootstrap';
import { httprequest } from '../helper/axios';

import Card from '../components/Card';
import MainBackground from '../components/MainBackground';
import gambar from '../assets/background.png';

class WelcomeScreen extends Component {
  state={
    apps: [],
    refreshing: false
  }

  componentDidMount(){
    this.refresh()
  }

  refresh = async() => {
    let res = await httprequest('webservice.php?fungsi=listapp', e => this.setState({refreshing: e}));
    this.setState({apps: res})
  }

  render(){
    return(
      <div style={{flex: 1, backgroundColor: '#DDDDDD', paddingBottom: 0.1}}>
        <MainBackground
          title="Quinn's Technology"
          tagline="Made life easier with technology"
          gambar={gambar}
          changeToPesan={() => this.props.changeToPesan()}
        />
        {
          this.state.apps.length > 0 ?
            this.state.apps.map(item => (
              <Row className="d-flex justify-content-center" style={{marginTop: -30, marginBottom: 40}} key={item.id}>
                <Card
                  namaApp={item.nama_app}
                  kategori={item.kategori}
                  tahun={item.tahun}
                  fungsi={item.main_function}
                  website={item.website}
                  gambar={item.foto}
                  appstore={item.link_app_store}
                  playstore={item.link_play_store}
                />
              </Row>
            ))
            : null
        }
      </div>
    )
  }
};

export default WelcomeScreen;
